import React from 'react';
import { Link } from 'react-router-dom';

export default function BlogCardSmall({ blog, setSelectedCategory }) {
  const {
    blog_title,
    blog_desc,
    blog_author,
    blog_preview_img,
    tags,
    blogPath,
    blog_url,
  } = blog;

  return (
    <article className="flex flex-col h-full" data-aos="zoom-y-out">
      <header>
        <Link to={`/blogs/${blog_url}`} className="block mb-6">
          <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
            <img
              className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out"
              src={blog_preview_img}
              width="352"
              height="198"
              alt="News 01"
            />
          </figure>
        </Link>
        <div className="mb-3">
          <ul className="flex flex-wrap text-xs font-medium -m-1">
            {tags.map((tag) => (
              <li className="m-1" key={tag}>
                <div
                  onClick={() => setSelectedCategory(tag)}
                  className="inline-flex text-center text-white py-1 px-3 rounded-full bg-black hover:bg-black transition duration-150 ease-in-out cursor-pointer"
                >
                  {tag}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
          <Link to={`/blogs/${blog_url}`} className="hover:underline">
            {blog_title}
          </Link>
        </h3>
      </header>
      <p className="text-gray-600 flex-grow">{blog_desc}</p>
      <footer className="text-sm flex items-center mt-4">
        <div className="flex flex-shrink-0 mr-3">
          <a className="relative" href="#0">
            <span className="absolute inset-0 -m-px" aria-hidden="true">
              <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
            </span>
            <img
              className="relative rounded-full"
              src={
                process.env.PUBLIC_URL +
                (blog_author
                  ? blog_author.author_avatar_url
                  : `${blogPath}/images/author.jpg`)
              }
              width="32"
              height="32"
              alt="Author 01"
            />
          </a>
        </div>
        <div>
          <span className="text-gray-600">By </span>
          <a className="font-medium hover:underline" href="#0">
            {blog_author.author_name}
          </a>
        </div>
      </footer>
    </article>
  );
}
