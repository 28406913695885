import React, { useEffect, useState } from 'react';
import BlogCardSmall from './BlogCardSmall';
import tags from '../data/blog-tags';

function BlogList({ blogs }) {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [filteredBlogs, setFilteredBlogs] = useState(blogs);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 3;

  useEffect(() => {
    setTotalPages(Math.ceil(filteredBlogs.length / pageSize));
    setPageNumber(1);
  }, [filteredBlogs]);

  useEffect(() => {
    let b = blogs;
    if (selectedCategory !== 'All') {
      b = blogs.filter((b) => b.tags && b.tags.includes(selectedCategory));
    }
    setFilteredBlogs(b.sort((a, b) => b.blog_priority - a.blog_priority));
  }, [blogs, selectedCategory]);

  function filterBlogs(category) {
    setSelectedCategory(category);
  }

  function showAllBlogs() {
    setSelectedCategory('All');
  }

  function goToNextPage() {
    canGoNextPage() && setPageNumber(pageNumber + 1);
  }

  function goToPrevPage() {
    canGoPrevPage() && setPageNumber(pageNumber - 1);
  }

  function canGoNextPage(params) {
    return totalPages !== pageNumber;
  }

  function canGoPrevPage(params) {
    return pageNumber !== 1;
  }

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Page header */}
          <div className="pb-12 md:pb-20 text-center md:text-left">
            <h1 className="h1">
              DVULN LABS
            </h1>
          </div>

          {/* Section tags */}
          <div className="border-b border-gray-300 pb-4 mb-12">
            <ul className="flex flex-wrap justify-center  md:justify-start font-medium -mx-5 -my-1">
              <li className="mx-5 my-1">
                <div
                  className={
                    'hover:underline cursor-pointer' +
                    (selectedCategory === 'All'
                      ? ' font-bold'
                      : ' text-gray-800')
                  }
                  onClick={showAllBlogs}
                >
                  All
                </div>
              </li>
              {tags.map((category) => (
                <li className="mx-5 my-1" key={category}>
                  <div
                    className={
                      'hover:underline cursor-pointer' +
                      (selectedCategory === category
                        ? ' font-bold'
                        : ' text-gray-800')
                    }
                    onClick={() => filterBlogs(category)}
                  >
                    {category}
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {/* Articles list */}
          <div className="max-w-sm mx-auto md:max-w-none">
            {/* Articles container */}
            <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">
              {filteredBlogs
                .slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
                .map((blog, i) => (
                  <BlogCardSmall
                    key={blog.blog_title}
                    blog={blog}
                    setSelectedCategory={setSelectedCategory}
                  />
                ))}
            </div>
          </div>
          <section className="flex gap-8 justify-center items-center p-8 text-xl font-extrabold">
            <div
              onClick={goToPrevPage}
              className={
                'select-none ' +
                (canGoPrevPage()
                  ? 'cursor-pointer hover:underline'
                  : 'text-gray-400')
              }
            >
              Newer
            </div>
            <div>
              <span className="block text-center">{pageNumber}</span>
              <span className="block h-0.5 bg-black"></span>
              <span className="block text-center">{totalPages}</span>
            </div>
            <div
              onClick={goToNextPage}
              className={
                'select-none ' +
                (canGoNextPage()
                  ? 'cursor-pointer hover:underline'
                  : 'text-gray-400')
              }
            >
              Older
            </div>
          </section>
        </div>
      </div>
    </section>
  );
}

export default BlogList;
