import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './blog-single.css'

function BlogSingle({ blog }) {
  const [headings, setHeadings] = useState([]);
  const [content, setContent] = useState('');
  const { blog_title, blog_author, blog_date, blogPath } = blog;

  useEffect(() => {
    if (blogPath) {
      fetch(`${blogPath}/content.md`)
        .then((response) => response.text())
        .then((text) => {
          setContent(text);
        });
    }
  }, [blogPath]);

useEffect(() => {
    document.title = "Dvuln Labs - " + blog_title
}, []);

  useEffect(() => {
    const htmlMarkdown = document.querySelector('#markdown-content');
    const headings = [...htmlMarkdown.querySelectorAll('h2')];

    const parsedHeadings = headings.map((heading) => {
      return {
        content: heading.innerText,
        id: heading.getAttribute('id'),
      };
    });

    setHeadings(parsedHeadings);
  }, [content]);

  function flatten(text, child) {
    return typeof child === 'string'
      ? text + child
      : React.Children.toArray(child.props.children).reduce(flatten, text);
  }

  function HeadingRenderer(props) {
    const children = React.Children.toArray(props.children);
    const text = children.reduce(flatten, '');
    const slug = getSlug(text);
    return React.createElement('h' + props.level, { id: slug, className: "h2-before" }, props.children);
  }

  function getSlug(text) {
    return text.toLowerCase().replace(/\W/g, '-');
  }

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto lg:max-w-none">
            <article>
              {/* Article header */}
              <header className="max-w-3xl mx-auto mb-20">
                {/* Title */}
                <h1 className="h1 text-center mb-4">{blog_title}</h1>
              </header>

              {/* Article content */}
              <div className="lg:flex lg:justify-between" data-sticky-container>
                {/* Sidebar */}
                <aside className="relative hidden lg:block w-64 mr-20 flex-shrink-0">
                  <div
                    data-sticky
                    data-margin-top="100"
                    data-sticky-for="768"
                    data-sticky-wrap
                  >
                    <h4 className="text-lg font-bold leading-snug tracking-tight mb-4">
                      Table of contents
                    </h4>
                    <ul className="font-medium -my-1">
                      <li className="py-1">
                        {headings.length ? (
                          headings.map((heading) => (
                            <a
                              className="flex items-center hover:underline"
                              href={'#' + heading.id}
                              key={heading.id}
                            >
                              <svg
                                className="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0"
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                              </svg>
                              <span>{heading.content}</span>
                            </a>
                          ))
                        ) : (
                          <Skeleton count={5} />
                        )}
                      </li>
                    </ul>
                  </div>
                </aside>

                {/* Main content */}
                <div className="w-full">
                  {/* Article meta */}
                  <div className="flex items-center mb-6">
                    <div className="flex flex-shrink-0 mr-3">
                      <a className="relative" href="#0">
                        <span
                          className="absolute inset-0 -m-px"
                          aria-hidden="true"
                        >
                          <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                        </span>
                        <img
                          className="relative rounded-full"
                          src={process.env.PUBLIC_URL + (blog_author ? blog_author.author_avatar_url : `${blogPath}/images/author.jpg`)}
                          width="32"
                          height="32"
                          alt="Author 04"
                        />
                      </a>
                    </div>
                    <div>
                      <span className="text-gray-600">By </span>
                      <a className="font-medium hover:underline" href="#0">
                        {blog_author ? blog_author.author_name : ''}
                      </a>
                      <span className="text-gray-600"> · {blog_date}</span>
                    </div>
                  </div>
                  <hr className="w-16 h-px pt-px bg-gray-200 border-0 mb-6" />

                  {/* Article body */}
                  <div className="text-lg">
                    <article
                      id="markdown-content"
                      className="prose lg:prose-xl"
                    >
                      {content ? (
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={content}
                          components={{
                            h2: HeadingRenderer,
                          }}
                        />
                      ) : (
                        <>
                          <Skeleton count={3} />
                          <br />
                          <Skeleton count={3} width={'70%'} />
                          <br />
                          <Skeleton count={5} />
                        </>
                      )}
                    </article>
                  </div>
                  <div>
                    <hr className="w-full h-px pt-px mt-8 bg-gray-200 border-0" />
                    <div className="mt-6">
                      <Link
                        to="/blog"
                        className="inline-flex items-center text-base text-blue-600 font-medium hover:underline"
                      >
                        <svg
                          className="w-3 h-3 fill-current text-blue-400 flex-shrink-0 mr-2"
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M.293 5.282L5 .5l1.414 1.436-3 3.048H12v2.032H3.414l3 3.048L5 11.5.293 6.718a1.027 1.027 0 010-1.436z" />
                        </svg>
                        <span>Back to the blog</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* Article footer */}
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogSingle;
