import React, { useEffect } from 'react';
import Header from '../partials/Header';
import BlogList from '../partials/BlogList';
import Footer from '../partials/Footer';


function Blog({ blogs }) {
  useEffect(() => {
    document.title = "Dvuln Labs"
  }, []);
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <BlogList blogs={blogs} />
        {/* <Newsletter /> */}
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Blog;
