import React, { useEffect, useState } from 'react';
import Header from '../partials/Header';
import BlogSingle from '../partials/BlogSingle';
import Footer from '../partials/Footer';
import { useParams } from 'react-router-dom';

function BlogPost({ blogs }) {
  const { blogURL } = useParams();

  const blog = blogs.find((b) => b.blog_url === blogURL);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        { blog && <BlogSingle blog={blog} /> }
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default BlogPost;
