import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';

import './css/style.scss';

import AOS from 'aos';
import Sticky from 'sticky-js';
import { focusHandling } from 'cruip-js-toolkit';

import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import PageNotFound from './pages/PageNotFound';

function App() {
  const [blogPaths, setBlogPaths] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
    // eslint-disable-next-line no-unused-vars
    const sticky = new Sticky('[data-sticky]');
  }, []);

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  useEffect(() => {
    fetch('/blogs/blogs.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not OK');
        }
        return response.json();
      })
      .then((res) => {
        setBlogPaths(res);
      });
  }, []);

  useEffect(() => {
    const fetchMetadata = (blogPath) => {
      fetch(`${blogPath}/metadata.json`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not OK');
          }
          return response.json();
        })
        .then((res) => {
          setBlogs((old) => {
            return old.concat({
              ...res,
              blogPath,
            });
          });
        });
    };

    if (blogPaths.length > 0) {
      setBlogs([]);

      blogPaths.forEach((blogPath) => {
        fetchMetadata(blogPath);
      });
    }
  }, [blogPaths]);

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Redirect to="/blog" />
        </Route>
        {/* <Route path="/pricing">
          <Pricing />
        </Route> */}
        <Route path="/blog">
          <Blog blogs={blogs} />
        </Route>
        <Route path="/blogs/:blogURL">
          <BlogPost blogs={blogs} />
        </Route>
        {/* 
        <Route path="/about">
          <About />
        </Route>
        <Route path="/documentation">
          <Documentation />
        </Route>
        <Route path="/support">
          <Help />
        </Route>
        <Route path="/signin">
          <SignIn />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route> */}
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
}

export default App;
